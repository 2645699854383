@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  @apply text-default;
  @apply font-Roboto;
  margin: 0;
  margin: 0 auto;
  letter-spacing: 2px;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

a:hover {
  color: #f7ebd3;
  transition: all 0.3s;
}
